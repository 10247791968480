
const logos = [
  { startsWith: 'hawaii', url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Hawaii_Warriors_logo.svg/1200px-Hawaii_Warriors_logo.svg.png' },
  { startsWith: 'cal poly', url: 'https://dbukjj6eu5tsf.cloudfront.net/gopoly.com/images/responsive_2020/logo_main.svg' },
  { startsWith: 'lbsu', url: 'https://www.clipartmax.com/png/middle/162-1625607_long-beach-state-49ers-mens-basketball-2018-schedule-california-state-university-long.png' },
  { startsWith: 'ucd', url: 'https://vblimages.blob.core.windows.net/images/4a8e9503-c9dd-404c-9097-57127dac0a64.jpg' },
  { startsWith: 'csub', url: 'https://upload.wikimedia.org/wikipedia/en/thumb/c/cc/Cal_State_Bakersfield_Roadrunners_logo.svg/1200px-Cal_State_Bakersfield_Roadrunners_logo.svg.png' },
  { startsWith: 'csun', url: 'https://gomatadors.com/images/logos/site/site.png' },
  { startsWith: 'sac', url: 'https://vblimages.blob.core.windows.net/images/93a7d486-e8a8-4e4a-87c9-d6b7d4f6ce05.png' },
  { startsWith: 'arizona', url: 'https://vblimages.blob.core.windows.net/images/c7c79bd0-fe39-4004-97f5-cf3395b0ad5f.jpg' },
  { startsWith: 'uc davis', url: 'https://vblimages.blob.core.windows.net/images/4a8e9503-c9dd-404c-9097-57127dac0a64.jpg' },
  { startsWith: 'usc', url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8IlnoQFwYsnjLNSy0tHiSfvqvKKC9BF1qoP7H6DcUVMfLEDR-Uu0Zhiz4MlHuUsFKkow&usqp=CAU' },
  { startsWith: 'ucla', url: 'https://1000logos.net/wp-content/uploads/2017/11/Font-UCLA-Logo.jpg' },
  { startsWith: 'lmu', url: 'https://s3.amazonaws.com/ncsa-gluster-2018/clientimages/14790college.jpg' },
  { startsWith: 'cal', url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/California_Golden_Bears_logo.svg/1200px-California_Golden_Bears_logo.svg.png' },
  { startsWith: 'stanford', url: 'https://identity.stanford.edu/wp-content/uploads/2020/07/SU_New_BlockStree_Red_darkbgrd.png' },
  { startsWith: 'pepper', url: 'https://s3.amazonaws.com/ncsa-gluster-2018/clientimages/15156college.jpg' }
]

const getLogo = (teamName) => {
  const l = logos.find(f => teamName.toLowerCase().startsWith(f.startsWith))
  return l && l.url
}

export { getLogo }
