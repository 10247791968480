<template>
  <message-card v-if="!division.showTeams">
    <h2 class="ma-12 text-center">
      <v-avatar color="red">
        <v-icon color="white">fas fa-eye-slash</v-icon>
      </v-avatar>
      <br class="hidden-md-and-up">
      The tournament host has not published the teams yet.
    </h2>
  </message-card>

  <v-data-table
    v-else
    :items="rows"
    :headers="headers"
    :loading="division.loading"
    :options.sync="page"
    :search="search"
    class="elevation-1"
    hide-default-footer
    dense
  >
    <template v-slot:progress>
      <v-progress-linear color="color3" indeterminate></v-progress-linear>
    </template>
    <template v-slot:[`item.bracketN`]="{ item }">
      {{item.bracket}}
    </template>
    <template v-slot:[`item.unix`]="{ item }">
      {{item.unix | unixToLocalDt}}
    </template>
    <template v-slot:[`item.finishInt`]="{ item }">
      <template v-if="item.finish < 9999">{{item.finish | ordinal}}</template>
      <template v-else>P</template>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <div v-if="($vuetify.breakpoint.xs && division.numOfPlayers <= 2 && !division.useTeamNames) || tournament.isShowcase" @click.stop="teamClick(item.id)">
        <div class="noWrap color3--text" v-for="p in item.players" :key="p.id">
          {{p.name}}{{division.showCommit && p.commitAbbr ? ` (${p.commitAbbr})` : ''}}
          <i class="fas fa-heart ml-2" v-if="p.isBookmarked(user)"></i>
        </div>
      </div>
      <div v-else class="color3--text clickable d-flex align-center" @click.stop="teamClick(item.id)">
        <v-img
          v-if="showLogos && item.logo"
          :src="item.logo"
          height="36"
          max-width="48"
          contain
        ></v-img>
        <country-flag
          v-if="item.countryCode"
          :country-code="item.countryCode"
          classes="mr-2"
        />
        {{item.name}}
        <i class="fas fa-heart ml-2" v-if="item.hasBookmarkedPlayer(user)"></i>
      </div>
    </template>
    <template v-slot:[`item.captain`]="{ item }">
      {{item.captains.map(m => m.name).join(' | ')}}
    </template>
    <template v-slot:[`item.players`]="{ item }">
      <div>
        <div class="noWrap d-flex" v-for="p in item.players" :key="p.id">
          <div v-if="showJersey" style="width: 30px; min-width: 30px" class="text-end mr-2">{{ p.jersey ? `#${p.jersey}` : null }} </div>
          <div class="d-flex grow justify-space-between">
            <div>
              {{p.name}}{{division.showCommit && p.commitAbbr ? ` (${p.commitAbbr})` : ''}}
              <i class="fas fa-heart ml-2" v-if="p.isBookmarked(user)"></i>
            </div>
            <div v-if="showMovr" class="caption grey--text ml-2">{{p.movr}}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:[`item.gradYear`]="{ item }">
      <div v-if="($vuetify.breakpoint.xs && division.numOfPlayers <= 2 && !division.useTeamNames) || tournament.isShowcase" @click.stop="teamClick(item.id)">
        <div v-for="p in item.players" :key="p.id">
          {{p.gradYear}}
        </div>
      </div>
      <div v-else>
        {{item.gradYear}}
      </div>
    </template>
    <template v-slot:[`item.commitAbbr`]="{ item }">
      <div v-if="($vuetify.breakpoint.xs && division.numOfPlayers <= 2 && !division.useTeamNames) || tournament.isShowcase" @click.stop="teamClick(item.id)">
        <div v-for="p in item.players" :key="p.id" style="min-height: 21px">
          {{p.commitAbbr}}
        </div>
      </div>
      <div v-else>
        {{item.commitAbbr}}
      </div>
    </template>
    <template v-slot:[`item.finishPoints`]="{ item }">
      <table width="100%" class="pt nested" v-if="!$vuetify.breakpoint.xsOnly">
        <tr v-for="p in item.points" :key="`${p.short}-${item.id}`">
          <td width="50%" class="text-right pa-0 pr-1">{{p.short}}:</td>
          <td width="50%" class="text-left pa-0">{{p.total | fixed1OrInt}}</td>
        </tr>
      </table>
      <span class="text-no-wrap" v-else>{{item.points.map(m => m.total) | pointArray}}</span>
    </template>
  </v-data-table>
</template>

<script>
import firstBy from 'thenby'
// import flatten from '@/helpers/ArrayFlatten'
import { mapGetters } from 'vuex'
import { getLogo } from '@/classes/CollegeLogos'
const CountryFlag = () => import('@/components/Utils/CountryFlag.vue')

export default {
  components: { CountryFlag },
  props: ['division', 'search', 'waitlist', 'showPoints', 'columns', 'showPlayers'],
  data () {
    return {
      page: { itemsPerPage: -1 }
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivisionJProp', 'getDivisionJProps', 'user']),
    a () {
      return this.division.bracketRoundsStarted
    },
    rows () {
      return this.division.bracketRounds.length > 1 && this.division.bracketRoundsStarted.length ? this.bracketTeams : this.teams
    },
    showJersey () {
      return !!this.rows.find(f => f.players.find(p => p.jersey))
    },
    hideSeed () {
      return this.getDivisionJProp(this.division.id, 'hideSeed') || this.tournament.isLeague
    },
    showMovr () {
      return this.columns.find(f => f.value === 'movr')
    },
    bracketTeams () {
      const sort = this.hideSeed ? 'name' : function (v1, v2) { return v1.iSeed - v2.iSeed }
      const d = this.division

      const teams2 = []
      const done = new Set()
      for (let i = d.bracketRounds.length - 1; i >= 0; i--) {
        const r = d.bracketRounds[i]
        r.teamsIds.forEach(id => {
          if (!done.has(id)) {
            const t = d.teams.find(f => f.id === id)
            t.bracket = d.bracketRounds.length === 1 ? d.name : `${d.name} - ${r.name}`
            t.bracketN = r.number
            teams2.push(t)
            done.add(id)
          }
        })
      }

      // const teams = flatten(d.bracketRounds.map(r => {
      //   return r.teamsIds.map(id => {
      //     const t = d.teams.find(f => f.id === id)
      //     t.bracket = d.bracketRounds.length === 1 ? d.name : `${d.name} - ${r.name}`
      //     t.bracketN = r.number
      //     return t
      //   })
      // }))

      return teams2.filter(f => !f.isDeleted).sort(
        firstBy(function (v1, v2) { return v1.bracketN - v2.bracketN })
          .thenBy(function (v1, v2) { return v1.iFinish - v2.iFinish })
          .thenBy(function (v1, v2) { return v1.finish - v2.finish })
          .thenBy(sort)
      )
    },
    teams () {
      const sort = this.hideSeed ? 'name' : 'iSeed'
      const t = this.division.activeTeams
      return t.sort(
        firstBy(function (v1, v2) { return v1.iFinish - v2.iFinish })
          .thenBy(function (v1, v2) { return v1.finish - v2.finish })
          .thenBy(sort)
          .thenBy('name')
      )
    },
    headers () {
      const heads = [
        { text: 'Finish', value: 'finishInt', align: 'left', sortable: true },
        { text: 'Team', value: 'name', align: 'left', sortable: true }
      ]
      if (this.showPlayers) {
        heads.push({ text: 'Players', value: 'players', align: 'left', sortable: false })
      }
      if (this.division.bracketRounds.length > 1) {
        heads.splice(1, 0, { text: 'Bracket', value: 'bracketN', align: 'left', sortable: true })
      }
      this.columns && heads.push(...this.columns.map(m => {
        return {
          text: m.header,
          value: m.value,
          align: 'left',
          sortable: true
        }
      }))
      if (!this.hideSeed) {
        heads.push({ text: 'Seed', value: 'seed', align: 'center', sortable: true })
      }
      this.showPoints && heads.push({ text: 'Points Earned', value: 'finishPoints', align: 'center', sortable: true })
      return heads
    },
    showLogos () {
      return true
    },
    useCollegeLogos () {
      return this.tournament.props && this.tournament.props.includes('ULogos')
    },
    noRep () {
      return this.division && this.division.props.includes('noRep')
    }
  },
  methods: {
    getLogo (teamName) {
      return getLogo(teamName)
    },
    teamClick (id) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    teamLog (team) {
      console.log(team.iFinish)
    }
  }
}
</script>

<style scoped>
  .noWrap {
    white-space: nowrap;
  }
  .deleted {
    text-decoration: line-through
  }
  .pt td {
    border: none !important;
    height: auto !important;
  }
  .mini >>> table tbody td,
  .mini >>> table thead th {
    padding: 0 !important;
    padding-left: 12px !important;
  }
</style>
